import React, { useState } from "react";
import "./experience.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { workExperience } from "./work_experience";
import { openWebPage } from "../../utils";

const Resume = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <section className="resume container section" id="experience">
      <h2 className="section__title">Work Experience</h2>

      <div className="resume__container">
        <Tabs
          className="tabs"
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          selectedTabClassName={"is-active"}
          selectedTabPanelClassName={"is-active"}
        >
          <TabList className="tab__list">
            {workExperience?.map((experience, index) => {
              const { idx, company } = experience;
              return (
                <Tab className="tab" key={`company-${idx}`}>
                  <button className="button">{company}</button>
                </Tab>
              );
            })}
          </TabList>

          {workExperience?.map((experience) => {
            const {
              idx,
              company,
              position,
              timeActive,
              title,
              companyVisible,
              companyLink,
              techStacks,
              description,
              extraLinks,
            } = experience;
            return (
              <TabPanel className="tab__panel" key={`panel-${idx}`}>
                {companyVisible === true ? (
                  <>
                    <h2 className="tab__panel-title">
                      {title} at &nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        href={companyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <b>
                          <u>{company}</u>
                        </b>
                      </a>
                    </h2>
                  </>
                ) : (
                  <h2
                    className="tab__panel-title"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      openWebPage(companyLink);
                    }}
                  >
                    <b>
                      <u>{title}</u>
                    </b>
                  </h2>
                )}

                {position && (
                  <p className="tab__panel-subtitle">{position} &nbsp;&nbsp;</p>
                )}
                <p className="tab__panel-timeActive">{timeActive}</p>
                <ul className="tab__panel-list">
                  {description?.map((info, index) => {
                    return (
                      <li
                        key={`info-${index}`}
                        dangerouslySetInnerHTML={{ __html: info }}
                      />
                    );
                  })}

                  {techStacks && (
                    <li style={{ marginBottom: 2 }}>
                      Technology used:{" "}
                      {techStacks?.map((info, index) => {
                        if (index === techStacks.length - 1) {
                          return (
                            <div
                              style={{
                                fontWeight: "bold",
                                display: "inline-block",
                              }}
                              key={`techStack-${index}`}
                            >
                              {info}.&nbsp;
                            </div>
                          );
                        } else {
                          return (
                            <div
                              style={{
                                fontWeight: "bold",
                                display: "inline-block",
                              }}
                              key={`techStack-${index}`}
                            >
                              {info},&nbsp;
                            </div>
                          );
                        }
                      })}
                    </li>
                  )}
                </ul>

                {extraLinks
                  ? extraLinks.map((extraLinks, index) => {
                      return (
                        <button
                          style={{ marginTop: 10 }}
                          className="btn"
                          key={`extra-${index}`}
                          onClick={() => window.open(extraLinks.link, "_blank")}
                        >
                          {extraLinks.text.toUpperCase()}
                        </button>
                      );
                    })
                  : null}
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default Resume;
