import React from "react";
import "./about.css";
import { contact, resume, talks } from "../../utils/links";
import { openWebPage } from "../../utils";

const About = () => {
  return (
    <section className="about container section" id="about">
      <h2 className="section__title">About Me </h2>

      <div className="about__container grid">
        <img
          loading="lazy"
          src={require("../../assets/profile_picture_2.png")}
          alt=""
          className="about__img"
        />

        <div className="about__data grid">
          <div className="about__info">
            <p className="about__description">
              {/*Hello! I'm Dhiraj, a software developer who likes to build stuff that makes a difference in the world.*/}
              With over <b>three years</b> of experience in Android development,
              I specialize in building large and maintainable applications. I am proficient with most modern tech stacks such as Kotlin, 
              TypeScript, Node.JS, Jetpack compose, Room, graphQL, gRPC, and MVVM architectural patterns to build a scalable application.
              {/*I usually work on <b>Android development</b>,{" "}*/}
              {/*<b>Web development</b>, and <b>Solana blockchain</b>.<br />*/}
              <br />
              <br />
              I believe in public learning, and I am constantly eager to learn
              new things and enjoy sharing what I have learned with others. I
              love interacting with folks and helping community members is what
              I like to do daily.
              <br />
              <br />I love to{" "}
              <a target="_blank" href={talks}>
                <b>
                  <u>speak</u>
                </b>
              </a>{" "}
              and have conducted multiple sessions to encourage and mentor
              students toward <b>programming</b>, <b>open source</b>, and{" "}
              <b>building tech communities</b>.
              <br />
              <br />
            </p>
            <ul className="about__list">
              <li>
                Building{" "}
                <a
                  target="_blank"
                  id="list-item"
                  href={"https://twitter.com/saganize"}
                >
                  <b>
                    <u>SAGAnize</u>
                  </b>
                </a>
                .
              </li>

              <li>
                Prev. Android Engineer at{" "}
                <a
                  target="_blank"
                  id="list-item"
                  href={"https://getairchat.com"}
                >
                  <b>
                    <u>Airchat</u>
                  </b>
                </a>
                .
              </li>

              <li>
                Prev. Full Stack Developer at{" "}
                <a
                  target="_blank"
                  id="list-item"
                  href={"https://ironforge.cloud"}
                >
                  <b>
                    <u>Ironforge</u>
                  </b>
                </a>
                .
              </li>
              <li>
                <a target="_blank" id="list-item">
                  Google Summer of Code <u>Mentor</u>
                </a>{" "}
                at{" "}
                <a
                  target="_blank"
                  id="list-item"
                  href={"https://github.com/52North/"}
                >
                  <b>
                    <u>52°North</u>
                  </b>
                </a>
                .
              </li>
              {/*<li>*/}
              {/*  <a target="_blank" */}
              {/*    id="list-item"*/}
              {/*    href={*/}
              {/*      "https://summerofcode.withgoogle.com/programs/2022/projects/xXN12jAU"*/}
              {/*    }*/}
              {/*  >*/}
              {/*    <u>Google Summer of Code Student</u>*/}
              {/*  </a>{" "}*/}
              {/*  at{" "}*/}
              {/*  <a target="_blank"  id="list-item" href={"https://github.com/52North/"}>*/}
              {/*    <b>*/}
              {/*      <u>52°North</u>*/}
              {/*    </b>*/}
              {/*  </a>*/}
              {/*  .*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*  <a target="_blank"  href={"https://github.com/solana-developers/solana-grants/"}>*/}
              {/*    <u>MLH Fellow Summer'22</u>*/}
              {/*  </a>{" "}*/}
              {/*  at{" "}*/}
              {/*  <a target="_blank"  href={"https://github.com/solana-labs"}>*/}
              {/*    <b>*/}
              {/*      <u>Solana Labs</u>*/}
              {/*    </b>*/}
              {/*  </a>*/}
              {/*  .*/}
              {/*</li>*/}
              <li>
                A{" "}
                <a
                  target="_blank"
                  href={"https://githubcampus.expert/cdhiraj40/"}
                >
                  <b>
                    <u>GitHub Campus Expert</u>
                  </b>
                </a>
                .
              </li>
              <li>
                Co-founder of{" "}
                <a
                  target="_blank"
                  href={"https://github.com/cerrituscoderscomm"}
                >
                  <b>
                    <u>Cerritus Coders</u>
                  </b>
                </a>
                .
              </li>
            </ul>
            <button className="btn" onClick={() => openWebPage(resume)}>
              CHECKOUT RESUME
            </button>
            <button className="btn" onClick={() => openWebPage(contact)}>
              CONNECT WITH ME
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
