import React from "react";
import "./talks.css";

import { talks } from "../../utils/links";
import { talksList } from "./talksList";
import { openWebPage } from "../../utils";
import { TalkProps } from "../../types/talk_types";

const Talks = () => {
  return (
    <section className="talk container section" id="talks">
      <h2 className="section__title">Talks</h2>

      <div className="talk__container grid">
        {talksList.map((talk) => {
          const { id, title, imagePath, videoID, date }: TalkProps = talk;
          return (
            <div className="talk__card" key={id}>
              <div className="talk__thumb">
                {videoID ? (
                  <div className="iframe-wrapper">
                    <iframe
                      loading="lazy"
                      width={"100%"}
                      height="379"
                      src={`https://www.youtube-nocookie.com/embed/${videoID}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media;
                                        gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : null}
                {imagePath ? (
                  <img
                    className={"talk__img"}
                    loading="lazy"
                    src={imagePath}
                    alt={title}
                  />
                ) : null}
              </div>
              <div className="talk__details">
                <h3 className="talk__title">{title}</h3>
              </div>

              <div className="talk-descp">{date}</div>
              <div className="talk-links">
                <button
                  className="btn talk-links-item"
                  onClick={() => openWebPage(`${talks}/${id}`)}
                >
                  Talk Details
                </button>
                <button
                  className="btn talk-links-item"
                  onClick={() =>
                    openWebPage(`https://youtube.com/watch?v=${videoID}`)
                  }
                >
                  Youtube Video
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="btn more-info-link"
        style={{ marginTop: "2rem" }}
        onClick={() => window.open(talks, "_blank")}
      >
        VIEW ALL TALKS
      </button>
    </section>
  );
};

export default Talks;
