import React from "react";
import "./projects.css";

import { projectList } from "./projectList";
import { projects } from "../../utils/links";
import { openWebPage } from "../../utils";

const Project = () => {
  return (
    <section className="project container section" id="projects">
      <h2 className="section__title">Projects</h2>

      <div className="project__container grid">
        {projectList.map((project) => {
          const {
            idx,
            title,
            description,
            imagePath,
            projectLink,
            demoLink,
            techStack,
          } = project;

          return (
            <div className="project__card" key={idx}>
              <div className="project__thumb">
                {demoLink ? (
                  <img
                    loading="lazy"
                    src={imagePath}
                    onClick={() => openWebPage(demoLink)}
                    alt={title}
                    className="project__img"
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={imagePath}
                    alt={title}
                    className="project__img"
                  />
                )}
              </div>
              <div className="project__details">
                <h3
                  className="project__title"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(projectLink, "_blank")}
                >
                  {title}
                </h3>
                <span dangerouslySetInnerHTML={{ __html: description }} />
                <div>
                  <br />
                  Technology used:{" "}
                  {techStack?.map((info, index) => {
                    if (index === techStack.length - 1) {
                      return (
                        <div
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                          }}
                          key={`techStack-${index}`}
                        >
                          {info}.&nbsp;
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                          }}
                          key={`techStack-${index}`}
                        >
                          {info},&nbsp;
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="btn more-info-link"
        style={{ marginTop: "2rem" }}
        onClick={() => window.open(projects, "_blank")}
      >
        VIEW ALL PROJECTS
      </button>
    </section>
  );
};

export default Project;
