import React from "react";
import "./App.css";
import Rightbar from "./components/rightbar/rightbar";
import Hero from "./components/hero/hero";
import About from "./components/about/about";
import Skills from "./components/skills/skills";
import Experience from "./components/work-experience/Experience";
import VolunteerExperience from "./components/volunteer-experience/volunteer-experience";
import ProjectsSection from "./components/projects/ProjectsSection";
import TalksSection from "./components/talks/TalksSection";
import BlogsSection from "./components/blogs/BlogsSection";
import Footer from "./components/footer/footer";

function App() {
  return (
    <div className="app">
      <Rightbar />
      <main className="main">
        <Hero />
        <About />
        {/*<Skills />*/}
        <Experience />
        <VolunteerExperience />
        <ProjectsSection />
        <TalksSection />
        <BlogsSection />
        <Footer />
      </main>
    </div>
  );
}

export default App;
