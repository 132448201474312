import { IconType } from "react-icons";
import { FaGithub, FaLinkedinIn, FaTwitter, FaYoutube } from "react-icons/fa";
import { SiGmail } from "react-icons/si";

interface HeroSocialItems {
  Icon: IconType;
  url: string;
}

export const heroSocialItems: HeroSocialItems[] = [
  {
    Icon: FaLinkedinIn,
    url: "https://www.linkedin.com/in/cdhiraj40",
  },
  {
    Icon: FaGithub,
    url: "https://www.github.com/cdhiraj40",
  },
  {
    Icon: FaTwitter,
    url: "https://www.twitter.com/cdhiraj40",
  },
  {
    Icon: SiGmail,
    url: "mailto:chauhandhiraj40@gmail.com",
  },
  {
    Icon: FaYoutube,
    url: "https://www.youtube.com/channel/UCTSqyTvuLkxlTZhxs84djbA",
  },
  // {
  //  Icon: FaTwitch,
  //  url: "https://www.twitch.tv/thefunnyintrovert"
  // }
];
