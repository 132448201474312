import React from "react";
import { heroSocialItems } from "./HeroSocialItems";

const HeroSocials = () => {
  return (
    <div className="socials">
      {heroSocialItems.map(({ Icon, url }, idx) => (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="socials__item"
          key={idx}
        >
          <Icon className="socials__link" />
        </a>
      ))}
      {/*<a href='https://www.twitch.tv/thefunnyintrovert' className='hero-social-link' target='__blank'>*/}
      {/*    <FaTwitch/>*/}
      {/*</a>*/}
    </div>
  );
};

export default HeroSocials;
