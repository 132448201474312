import React, { useState } from "react";
import "./volunteer-experience.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { volunteerWorkExperience } from "./volunteer_experience";
import { openWebPage } from "../../utils";

const VolunteerExperience = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <section
      className="volunteer_experience container section"
      id="volunteer-experience"
    >
      <h2 className="section__title">Volunteer Experience</h2>

      <div className="volunteer_experience__container">
        <Tabs
          className="tabs"
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          selectedTabClassName={"is-active"}
          selectedTabPanelClassName={"is-active"}
        >
          <TabList className="tab__list">
            {volunteerWorkExperience.map((experience, index) => {
              const { idx, company } = experience;
              return (
                <Tab className="tab" key={`company-${idx}`}>
                  <button className="button">{company}</button>
                </Tab>
              );
            })}
          </TabList>

          {volunteerWorkExperience.map((experience) => {
            const {
              idx,
              company,
              position,
              timeActive,
              title,
              companyVisible,
              companyLink,
              techStacks,
              description,
              extraLinks,
            } = experience;
            return (
              <TabPanel className="tab__panel" key={`panel-${idx}`}>
                {companyVisible === true ? (
                  <>
                    <h2 className="tab__panel-title">
                      {title} at &nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        href={companyLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <b>
                          <u>{company}</u>
                        </b>
                      </a>
                    </h2>
                  </>
                ) : (
                  <h2
                    className="tab__panel-title"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      openWebPage(companyLink);
                    }}
                  >
                    <b>
                      <u>{title}</u>
                    </b>
                  </h2>
                )}

                <div style={{ marginBottom: "2px" }}>
                  <p
                    style={{
                      fontSize: "14px",
                      float: "left",
                      fontWeight: "bold",
                    }}
                  >
                    {position} &nbsp;&nbsp;
                  </p>
                  <p style={{ fontSize: "14px" }}>{timeActive}</p>
                </div>
                <ul className="tab__panel-list">
                  {description.map((info, index) => {
                    return (
                      <li key={`info-${index}`}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: info,
                          }}
                        ></div>
                      </li>
                    );
                  })}
                  {techStacks ? (
                    <li style={{ marginBottom: 2 }}>
                      Technology used:{" "}
                      {techStacks.map((info, index) => {
                        return (
                          <div
                            style={{
                              fontWeight: "bold",
                              display: "inline-block",
                            }}
                            key={`techStack-${index}`}
                          >
                            {info},&nbsp;
                          </div>
                        );
                      })}
                    </li>
                  ) : null}
                </ul>
                {extraLinks
                  ? extraLinks.map((extraLinks, index) => {
                      return (
                        <button
                          style={{ marginTop: 10 }}
                          className="btn"
                          key={`extra-${index}`}
                          onClick={() => window.open(extraLinks.link, "_blank")}
                        >
                          {extraLinks.text.toUpperCase()}
                        </button>
                      );
                    })
                  : null}
              </TabPanel>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default VolunteerExperience;
