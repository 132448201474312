import { WorkExperienceProps } from "../../types/work_experience_types";

export const volunteerWorkExperience: WorkExperienceProps[] = [
  {
    idx: 1,
    title: "Cerritus Coders",
    position: "Co-Founder",
    company: "Cerritus Coders",
    companyLink: "https://github.com/cerrituscoderscomm",
    companyVisible: false,
    timeActive: "Dec 2021 - Present",
    description: [
      "This community is run solely by students, for students, to encourage development, open-source, and help the community unlock their full potential, one step at a time",
      "Helping folks to get opportunities by encouraging them to learn, build and share in public.",
      "Mentored over 300 students to get started with development and open-source.",
    ],
    extraLinks: [
      {
        text: "Twitter",
        link: "https://twitter.com/cerrituscoders",
      },
      {
        text: "Linkedin",
        link: "https://linkedin.com/company/cerrituscoders",
      },
      {
        text: "GitHub",
        link: "https://github.com/cerrituscoderscomm",
      },
    ],
  },
  {
    idx: 2,
    title: "GDSC-TCET",
    position: "Advisory",
    company: "GDSC-TCET",
    companyLink: "https://www.linkedin.com/company/dsc-tcet/",
    companyVisible: false,
    timeActive: "June 2022 - Present",
    description: [
      "Helping the GDSC-TCET team to give exposure to open source and various technologies in the college.",
      "Providing general advice related to all domains.",
    ],
    extraLinks: [
      {
        text: "Twitter",
        link: "https://twitter.com/DscTcet",
      },
      {
        text: "Linkedin",
        link: "https://linkedin.com/company/dsc-tcet/",
      },
    ],
  },
  {
    idx: 3,
    title: "GDSC-TCET",
    position: "Android Core member",
    company: "GDSC-TCET",
    companyLink: "https://www.linkedin.com/company/dsc-tcet/",
    companyVisible: false,
    timeActive: "Aug 2021 - June 2022",
    description: [
      "Brought awareness, conducted workshops to increase android culture on campus.",
      "Worked with the Android team to share resources among the whole university.",
    ],
    extraLinks: [
      {
        text: "Twitter",
        link: "https://twitter.com/DscTcet",
      },
      {
        text: "Linkedin",
        link: "https://linkedin.com/company/dsc-tcet/",
      },
    ],
  },
  {
    idx: 4,
    title: "Nirmaan Hyperloop",
    position: "Software Co-Lead",
    company: "Nirmaan Hyperloop",
    companyLink: "https://www.linkedin.com/company/nirmaan-hyperloop",
    companyVisible: false,
    timeActive: "Apr 2021 - June 2022",
    description: [
      "Co-Handled the Software team and bringing the results for the sub-systems.",
      "Lead the Software team to make POD Control System.",
    ],
    techStacks: ["Python", "LCM", "PyQt5", "PySide2"],
    extraLinks: [
      {
        text: "Twitter",
        link: "https://twitter.com/nirmaanloop",
      },
      {
        text: "Linkedin",
        link: "https://www.linkedin.com/company/nirmaan-hyperloop",
      },
    ],
  },
  {
    idx: 5,
    title: "GirlScript Summer of Code",
    position: "Mentor",
    company: "GirlScript Summer of Code",
    companyLink: "https://www.linkedin.com/company/girlscriptsoc/",
    companyVisible: false,
    timeActive: "Mar 2022 - May 2022",
    description: [
      "Actively maintained a project, reviewed multiple pull requests.",
      "Helped beginners to get started with open source and program journey.",
    ],
    extraLinks: [
      {
        text: "Twitter",
        link: "https://twitter.com/girlscriptsoc",
      },
      {
        text: "Linkedin",
        link: "https://www.linkedin.com/company/girlscriptsoc",
      },
    ],
  },
];
