import React, { useState } from "react";
import "./rightbar.css";
import { RiMenu2Line } from "react-icons/ri";
import { rightBarItems } from "./rightbarItems";

const Rightbar = () => {
  const [toggle, showMenu] = useState(false);

  return (
    <>
      <aside className={toggle ? "aside show-menu" : "aside"}>
        <RiMenu2Line
          style={{
            opacity: 0,
          }}
        />
        <nav className="nav">
          <div className="nav__menu">
            <ul className="nav__list">
              {rightBarItems.map(({ Icon, label, newTab, url }, idx) =>
                newTab ? (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav__item"
                    key={idx}
                  >
                    <Icon className="nav__link" />
                    <p className="nav__label">{label}</p>
                  </a>
                ) : (
                  <a href={url} className="nav__item" key={idx}>
                    <Icon className="nav__link" />
                    <p className="nav__label">{label}</p>
                  </a>
                )
              )}
            </ul>
          </div>
        </nav>
        <div className="nav__footer">
          <button className="nav__link footer__button">
            <RiMenu2Line
              style={{
                opacity: 0,
              }}
            />
          </button>
        </div>
      </aside>
      <div
        className={toggle ? "nav__toggle nav__toggle-open" : "nav__toggle"}
        onClick={() => showMenu(!toggle)}
      >
        <RiMenu2Line />
      </div>
    </>
  );
};

export default Rightbar;
