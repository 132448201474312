import React from "react";
import "./footer.css";
import { FaArrowUp } from "react-icons/fa";
import { openWebPage } from "../../utils";
import { contact } from "../../utils/links";

const Footer = () => {
  const openContact = () => {
    openWebPage(contact);
  };
  return (
    <div className="footer" id="footer">
      <p className="footer__text" onClick={openContact}>
        Built by <b>&nbsp;@cdhiraj40</b>
      </p>
      <a href="#home" className="btn footer__btn">
        <FaArrowUp />
      </a>
    </div>
  );
};

export default Footer;
