import { IconType } from "react-icons";
import {
  RiBriefcase2Line,
  RiChat3Line,
  RiDraftLine,
  RiFileList3Line,
  RiHome2Line,
  RiStackLine,
  RiUser3Line,
} from "react-icons/ri";
import { FaLaptopCode } from "react-icons/fa";
import { TbMicrophone2 } from "react-icons/tb";

interface RightBarItem {
  Icon: IconType;
  label: string;
  newTab?: boolean;
  url: string;
}

export const rightBarItems: RightBarItem[] = [
  {
    Icon: RiHome2Line,
    label: "Home",
    url: "#home",
  },
  {
    Icon: RiUser3Line,
    label: "About",
    url: "#about",
  },
  // {
  //   Icon: RiStackLine,
  //   label: "Skills",
  //   url: "#skills",
  // },
  {
    Icon: RiBriefcase2Line,
    label: "Experience",
    url: "#experience",
  },
  {
    Icon: FaLaptopCode,
    label: "Projects",
    url: "#projects",
  },
  {
    Icon: RiFileList3Line,
    label: "Resume",
    newTab: true,
    url: "https://resume.cdhiraj40.dev",
  },
  {
    Icon: TbMicrophone2,
    label: "Talks",
    newTab: false,
    url: "#talks",
  },
  {
    Icon: RiDraftLine,
    label: "Blogs",
    url: "#blogs",
  },
  // {
  //     Icon: DiOpensource,
  //     label: "Cerritus Coders",
  //     url: '#cerritus-coders',
  // },
  {
    Icon: RiChat3Line,
    label: "Contact",
    newTab: true,
    url: "https://links.cdhiraj40.dev",
  },
];
