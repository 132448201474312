import { BlogProps } from "../../types/blog_types";

export const blogList: BlogProps[] = [
  {
    idx: 0,
    id: "envirocar-app-voice-command-final-blog-post",
    title: "enviroCar App: Voice Command – Final Term Post",
    description:
      "This year in Google Summer of Code 2022, the goal of the enviroCar App: Voice Command project was to provide a better user-friendly experience by automating the actions of the enviroCar Android application using voice commands. I have worked on various goals of the project which you can read about in my previous blogs. In my final blog post, I want to present the outcomes of the project, which have been achieved over the last 12 weeks.",
    tags: ["GSoC", "Android", "52°North", "enviroCar"],
    date: "Sept, 2022",
  },
  {
    idx: 1,
    id: "envirocar-app-voice-command-mid-term-blog-post",
    title: "enviroCar App: Voice Command – Mid Term Post",
    description:
      "The enviroCar App: Voice Command project aims to automate the enviroCar application’s actions via voice commands to provide a user-friendly experience. My goal is to add voice command features to the app as part of Google Summer of Code 2022. You can read my earlier blog, which provides an overview of this project. This blog post describes the intermediate results up to now. The project’s goals and their implementations have made significant progress.",
    tags: ["GSoC", "Android", "52°North", "enviroCar"],
    date: "Aug, 2022",
  },
  {
    idx: 2,
    id: "introduction-to-the-envirocar-app-voice-command-project",
    title: "Introduction to the enviroCar App: Voice Command",
    description:
      "There are a lot of reasons why it is useful to have your phone with you in the car and enviroCar encourages you to do so. At the same time, it’s a potentially dangerous distraction to use your phone while driving. Interacting with the enviroCar Android app while driving is a serious concern, as it may result in an accident. Hence, I would like to add voice command features to the app as part of Google Summer of Code 2022. Such a technical feature enables users to use the application’s functions through speaking instructions instead of the physical touch on the app. The voice command features will be implemented as part of a separate module that can be injected into the existing Android app.",
    tags: ["GSoC", "Android", "52°North", "enviroCar"],
    date: "June, 2022",
  },
];
