import React from "react";
import "./hero.css";
import HeroSocials from "./HeroSocials";
import { openWebPage } from "../../utils";
import { contact } from "../../utils/links";

let profile_pic = require("../../assets/profile_picture_2.png");

const Hero = () => {
  return (
    <section className="hero container" id="home">
      <div id="hero-col-wrapper">
        <div id="hero-col-1">
          <h1>Dhiraj Chauhan</h1>
          <h2>Software Developer</h2>
          <HeroSocials />
          <button className="contact-btn" onClick={() => openWebPage(contact)}>
            GET IN TOUCH
          </button>
        </div>

        <div id="hero-col-2">
          <img
            loading="lazy"
            className="icon"
            src={profile_pic}
            alt="Dhiraj Chauhan"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
