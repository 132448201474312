import React from "react";
import "./blogs.css";

import { blogs } from "../../utils/links";
import { blogList } from "./blogList";
import { truncateText } from "../../utils";

import { BlogProps } from "../../types/blog_types";

const BlogSection = () => {
  return (
    <section className="blog container section" id="blogs">
      <h2 className="section__title">Recent Blogs</h2>

      <div className="blog__container grid">
        {blogList.map((blog) => {
          const { idx, id, title, description, tags, date }: BlogProps = blog;
          return (
            <div className="blog__card" key={idx}>
              <div
                className="blog__thumb"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    `https://blogs.cdhiraj40.dev/posts/${id}`,
                    "_blank"
                  )
                }
              >
                <img
                  loading="lazy"
                  src={require("../../assets/blog/gsoc.png")}
                  alt={title}
                  className="blog__img"
                />
              </div>
              <div className="blog__details">
                <h3
                  className="blog__title"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      `https://blogs.cdhiraj40.dev/posts/${id}`,
                      "_blank"
                    )
                  }
                >
                  {title}
                </h3>
                <div className="blog-descp">
                  {truncateText(description, 100)}
                </div>
                <div>
                  <br />
                  Tags:{" "}
                  {tags?.map((info, index) => {
                    if (index === tags.length - 1) {
                      return (
                        <div
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                          }}
                          key={`tag-${index}`}
                        >
                          {info}.&nbsp;
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            fontWeight: "bold",
                            display: "inline-block",
                          }}
                          key={`tag-${index}`}
                        >
                          {info},&nbsp;
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <button
        className="btn more-info-link"
        style={{ marginTop: "2rem" }}
        onClick={() => window.open(blogs, "_blank")}
      >
        VIEW ALL BLOGS
      </button>
    </section>
  );
};

export default BlogSection;
