import { WorkExperienceProps } from "../../types/work_experience_types";

export const workExperience: WorkExperienceProps[] = [
  {
    idx: 1,
    title: "Android Engineer",
    company: "Airchat",
    companyLink: "https://www.getairchat.com/",
    companyVisible: true,
    timeActive: "May 2023 - November 2023",
    description: [
      "Led the development of the Android app, mirroring <b>a year-old</b> iOS app, and launched it on Play Store in just <b>4 months</b>.",
      "Delivered weekly feature updates, enhancing user engagement and app functionality.",
      "Coordinate cross team initiatives with Web/iOS and backend teams.",
    ],
    techStacks: ["Kotlin", "Jetpack Compose", "gRPC"],
    extraLinks: [
      {
        text: "Application Link",
        link: "https://play.google.com/store/apps/details?id=com.wooshinc.getairchat",
      },
    ],
  },
  {
    idx: 2,
    title: "Full-Stack Developer",
    company: "Ironforge",
    companyLink: "https://ironforge.cloud",
    companyVisible: true,
    timeActive: "Dec 2022 - May 2023",
    description: [
      "Developed automated and efficient infrastructure to onboard developers onto the Solana ecosystem.",
      "Led open source development efforts and increased developer engagement with the <a target='_blank' href='https://soldev.app'> SolDev </a> platform.",
    ],
    techStacks: ["Rust", "Next.js", "TypeScript", "MongoDB"],
  },
  {
    idx: 3,
    title: "Google Summer Of Code 2023 Mentor",
    position: "Software Developer",
    company: "52°North GmbH",
    companyLink: "https://github.com/52North/",
    companyVisible: true,
    timeActive: "March 2023 - Sept 2023",
    description: [
      "<a target='_blank' href='https://github.com/enviroCar/enviroCar-app'><b><u>enviroCar</u></b></a> is an Android application that can be used to collect Extended Floating Car Data.",
      "<a target='_blank' href='https://wiki.52north.org/Projects/GSoC2023ProjectIdeas#enviroCar_App:_Voice_Command'><b><u>[Project enviroCar App: Voice Command]</b></u></a>: I will be mentoring the project to stabilize the current implementation and transform various app interactions into voice commands.",
    ],
  },
  {
    idx: 4,
    title: "Google Summer Of Code 2022 participant",
    position: "Software Developer",
    company: "52°North GmbH",
    companyLink: "https://github.com/52North/",
    companyVisible: true,
    timeActive: "May 2022 - Sept 2022",
    description: [
      "<a target='_blank' href='https://github.com/enviroCar/enviroCar-app'><b><u>enviroCar</u></b></a> is an Android application that can be used to collect Extended Floating Car Data.",
      "<a target='_blank' href='https://wiki.52north.org/Projects/GSoC2022ProjectIdeas#enviroCar_App:_Voice_Command'><b><u>[Project enviroCar App: Voice Command]</b></u></a>: I Worked on automating the enviroCar application’s actions via voice commands such as Start/stop track recording, select car to provide a user-friendly experience.",
    ],
    techStacks: ["Java", "Kotlin", "ML", "Rasa", "Python", "Docker"],
    extraLinks: [
      {
        text: "Accepted Proposal",
        link: "https://summerofcode.withgoogle.com/programs/2022/projects/xXN12jAU",
      },
      {
        text: "enviroBot Link",
        link: "https://github.com/enviroCar/envirocar-rasa-bot",
      },
      {
        text: "Project Link",
        link: "https://github.com/enviroCar/enviroCar-app/pull/961",
      },
    ],
  },
  {
    idx: 5,
    title: "Major League Hacking Fellowship: Open Source",
    position: "Software Developer",
    company: "Solana Labs",
    companyLink: "https://github.com/solana-labs/",
    companyVisible: true,
    timeActive: "May 2022 - Aug 2022",
    description: [
      "Built a marketplace from scratch that builders can host Solana grants that community can contribute to.",
      "Worked on creating <b>smart contracts</b>, <b>web3 DB </b>and <b>front end integration</b>.",
      "Collaborated with pod mates to design various implementations and completed the project <b>under 12 weeks</b>.",
    ],
    techStacks: ["Next.js", "Rust", "Typescript", "Anchor", "Solana-web3"],
    extraLinks: [
      {
        text: "Project Link",
        link: "https://github.com/solana-developers/solana-grants",
      },
    ],
  },
  {
    idx: 6,
    title: "Gramoday",
    position: "Android Developer Intern",
    company: "Agrilinks Technologies",
    companyLink: "https://www.gramoday.net/",
    companyVisible: false,
    timeActive: "Feb 2022 - Apr 2022",
    description: [
      "Collaborated with teams to define, design, and ship new features for the mobile apps in the <b>bi-weekly sprint</b>",
      "Worked on <b>bug fixing</b> and <b>improved application performance</b> reported by in-house QA.",
    ],
    techStacks: ["Kotlin", "Firebase", "MongoDB"],
    extraLinks: [
      {
        text: "Application Link",
        link: "https://play.google.com/store/apps/details?id=net.gramoday.agriculture",
      },
    ],
  },
  {
    idx: 7,
    title: "XIRCLS - Collaborative Marketing Saas",
    position: "Android Developer Intern",
    company: "XIRCLS",
    companyLink: "https://www.linkedin.com/company/xircls/",
    companyVisible: false,
    timeActive: "September 2021 - November 2021",
    description: [
      "<b>3SRB</b><a target='_blank' href='https://play.google.com/store/apps/details?id=com.threesrb.app&hl=en_IN&gl=US'><b><u>(Google Play Store Link)</b></u></a>",
      "Worked with the Android team to common development tools, and frameworks/libraries that the group will use for the development.",
      "Updated <b>downloading music</b> and <b>notes feature</b> with respect to the android 11’s new storage updates.",
      "Wrote elegant, self-documenting code, easy to read and adapt for other developers.",
      "<b>XIRCLS</b><a target='_blank' href='https://play.google.com/store/apps/details?id=com.nucleus.xirclslive'><b><u>(Google Play Store Link)</b></u></a>",
      "Worked on the <b>dark theme</b> of the app to provide a better user experience.",
      "Ensure application is properly integrated with the APIs.",
    ],
    techStacks: ["Java", "Python", "Firebase", "Postman"],
    extraLinks: [],
  },
];
