import { TalkProps } from "../../types/talk_types";

export const talksList: TalkProps[] = [
  {
    idx: 0,
    id: "android-devel-101",
    title: "Android Development - 101", // merge with other talks -> Android Development 101 Day 1, Android Development 101 Day 2,
    // subTitle: "<a href={\"https://github.com/cerrituscoderscomm\"}><b><u>Cerritus Coders</u></b></a> Summer Bootcamp Day 1",
    videoID: "iyLhefk0syk",
    date: "Sept, 2022",
  },
  {
    idx: 1,
    id: "git-basics",
    title: "Basics of Git & GitHub", // merge with other talks -> Android Development 101 Day 1, Android Development 101 Day 2,
    // subTitle: "<a href={\"https://github.com/cerrituscoderscomm\"}><b><u>Cerritus Coders</u></b></a> Summer Bootcamp Day 2",
    imagePath: require("../../assets/talks/git-and-github/1.png"),
    date: "June, 2022",
  },
];
